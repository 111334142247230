import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import s from './full-date-location.scss'

interface FullDateLocationProps {
  event: wix.events.Event
  dataHook?: string
  dateClassName: string
  locationClassName: string
}

export const FullDateLocation = (props: FullDateLocationProps) => {
  const {
    allBreakpoints: {isListDateVisible, isListLocationVisible},
  } = useVisibilityStyles()

  return (
    <FullDateLocationBase
      {...props}
      dateVisibilityClass={s.date}
      locationVisibilityClass={s.location}
      dateVisible={isListDateVisible()}
      locationVisible={isListLocationVisible()}
    />
  )
}

export const AdditionalInfoFullDateLocation = (props: FullDateLocationProps) => {
  const {
    allBreakpoints: {isListExtraInfoDateVisible, isListExtraInfoLocationVisible},
  } = useVisibilityStyles()

  return (
    <FullDateLocationBase
      {...props}
      dateVisibilityClass={s.extraInfoDate}
      locationVisibilityClass={s.extraInfoLocation}
      dateVisible={isListExtraInfoDateVisible()}
      locationVisible={isListExtraInfoLocationVisible()}
    />
  )
}

interface FullDateLocationBaseProps extends FullDateLocationProps {
  dateVisibilityClass: string
  locationVisibilityClass: string
  dateVisible: boolean
  locationVisible: boolean
}

const FullDateLocationBase = ({
  event,
  dataHook,
  dateClassName,
  locationClassName,
  dateVisibilityClass,
  locationVisibilityClass,
  dateVisible,
  locationVisible,
}: FullDateLocationBaseProps) => {
  const {fullDate} = useEventDateInformation(event.id)

  return (
    <div data-hook={dataHook ?? DH.fullDateLocation}>
      {dateVisible ? (
        <div className={classNames(dateVisibilityClass, dateClassName)} data-hook="date">
          {fullDate}
        </div>
      ) : null}
      {locationVisible ? (
        <div className={classNames(locationVisibilityClass, locationClassName)} data-hook="location">
          {getFormattedFullLocation(event)}
        </div>
      ) : null}
    </div>
  )
}
