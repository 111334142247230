import {isDarkColor} from '@wix/panda-js-utils'
import {useStyles} from '@wix/tpa-settings/react'
import {BUTTONS_STYLE, ExperimentNames, LIST_LAYOUT, MOBILE_WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import stylesParams from '../../../stylesParams'
import {useWidgetActions} from '../../hooks/state-provider'
import {Spinner} from '../spinner'
import s from './load-more-button.scss'

const BLACK_RGB = 'rgb(0,0,0)'
const WHITE_RGB = 'rgb(255,255,255)'

export const LoadMoreButton = ({
  mobile,
  hasMore,
  moreLoading,
}: {
  mobile?: boolean
  hasMore: boolean
  moreLoading: boolean
}) => {
  const {t} = useTranslation()
  const {loadEventsPage} = useWidgetActions()
  const {get: getSetting} = useSettings()
  const {get} = useStyles()
  const limit = getSetting(settingsParams.eventsPerPage)

  const hollowButton = [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(get(stylesParams.listButtonStyle))
  const widgetBackgroundColor = get(stylesParams.widgetBackgroundColor)
  const listHollowButtonFontColor = get(stylesParams.listHollowButtonFontColor)
  const listButtonBackgroundColor = get(stylesParams.listButtonBackgroundColor)
  const listFullButtonFontColor = get(stylesParams.listFullButtonFontColor)
  const onImageLayout = mobile
    ? get(stylesParams.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.ON_IMAGE
    : get(stylesParams.listLayout) === LIST_LAYOUT.ON_IMAGE

  const getColorOverride = () => {
    if ((!hollowButton && listButtonBackgroundColor.opacity > 0) || !onImageLayout) {
      return null
    }

    const colorValue = hollowButton ? listHollowButtonFontColor.value : listFullButtonFontColor.value
    const darkColor = isDarkColor(colorValue)

    if (isDarkColor(widgetBackgroundColor.value)) {
      return darkColor ? WHITE_RGB : colorValue
    }

    return darkColor ? colorValue : BLACK_RGB
  }

  const colorOverride = getColorOverride()

  const {experiments} = useExperiments()
  const cornerRadiusAndShadowEnabled = experiments.enabled(ExperimentNames.CornerRadiusAndShadow)

  if (!hasMore && !moreLoading) {
    return null
  }

  return (
    <div
      data-hook={DH.loadMoreButtonContainer}
      className={classNames(s.container, {
        [s.mobile]: mobile,
        [s.cornerRadiusEnabled]: cornerRadiusAndShadowEnabled,
        [s.overridePadding]:
          get(stylesParams.listLayout) === LIST_LAYOUT.LIST
            ? experiments.enabled(ExperimentNames.WidgetPaddingSettings)
            : cornerRadiusAndShadowEnabled,
      })}
    >
      {moreLoading && (
        <div className={s.spinnerWrapper}>
          <Spinner diameter={40} />
        </div>
      )}
      <button
        data-hook={DH.loadMoreButton}
        className={classNames(s.button, {
          [s.cornerRadiusEnabled]: cornerRadiusAndShadowEnabled,
          [s.hidden]: moreLoading,
          [s.mobile]: mobile,
          [s.onImage]: onImageLayout,
        })}
        style={{color: colorOverride, borderColor: colorOverride}}
        type="button"
        onClick={() => loadEventsPage({limit})}
      >
        {t('loadMoreButton')}
      </button>
    </div>
  )
}
